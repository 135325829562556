import MUIAutocomplete from '@mui/material/Autocomplete'
import MUIPopper from '@mui/material/Popper'
import { styled } from '@mui/material/styles'

export const Autocomplete = ({ ...props }: any) => (
  <StyledAutocomplete {...props} PopperComponent={Popper} />
)

const StyledAutocomplete = styled(MUIAutocomplete)({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: 2,
  },
  '& .MuiTextField-root label': {
    top: '-4px',
  },
})

const Popper = ({ ...props }: any) => (
  <StyledPopper {...props} placement="bottom" />
)

const StyledPopper = styled(MUIPopper)({
  '& .MuiAutocomplete-listbox': {
    color: 'var(--primary-1000)',
    fontSize: 16,
    fontFamily: 'Jokker',
  },
  '& .MuiAutocomplete-paper': {
    borderRadius: 0,
  },
})
