import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Close from '@mui/icons-material/Close'
import { useRecoilState, useRecoilValue } from 'recoil'
import { modalContentState, modalOpenState } from '../state'
import { base } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import { Modal as StyledModal } from './mui/Modal'

export const Modal = () => {
  const [open, setOpen] = useRecoilState(modalOpenState)
  const { title, content, showCloseButton } = useRecoilValue(modalContentState)

  return (
    <StyledModal open={open} onClose={() => setOpen(false)}>
      <Layout>
        <Header>
          <h2>{title}</h2>
          <IconButton color="info" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Header>
        <Content>{content}</Content>
      </Layout>
    </StyledModal>
  )
}

const Layout = styled.div`
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--surface);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: ${base * 120}px;
`

const Header = styled.div`
  background-color: var(--primary-1000);

  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: ${base * 4}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: var(--surface);
    font-size: ${base * 4}px;
  }
`

const Content = styled.div`
  padding: ${base * 4}px;
`
