import styled from '@emotion/styled'
import { FC, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { GetCollaboratorsResponse } from 'shared/readmodels/getCollaborators.schema'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { modalOpenState } from '../../state'
import { base } from '../../styles/spacing'
import { postCommand } from '../../utils/postCommand'
import { Button } from '../mui/Button'
import { TextField } from '../mui/TextField'

type InviteCollaboratorProps = {
  jobId: NonNullable<GetJobByIdResponse>['_id']
  collaborators: GetCollaboratorsResponse
  onSubmitted: () => void
}

type CollaboratorInput = {
  email: string
  firstName: string
  lastName: string
}

type CollaboratorInputError = Partial<CollaboratorInput>

const defaultErrorObject = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
}

const academicworkDomains = [
  'academicwork.se',
  'academicwork.fi',
  'academicwork.de',
  'academicwork.dk',
  'academicwork.no',
  'academicwork.ch',
  'aw.com',
  'academicwork.com',
]

export const InviteCollaborator: FC<InviteCollaboratorProps> = ({
  jobId,
  collaborators,
  onSubmitted,
}) => {
  const [errorState, setErrorState] =
    useState<CollaboratorInputError>(defaultErrorObject)
  const [newCollaborator, setNewCollaborator] = useState<CollaboratorInput>({
    email: '',
    firstName: '',
    lastName: '',
  })

  const setOpen = useSetRecoilState(modalOpenState)

  const inviteCollaborator = postCommand('inviteCollaborator')

  useEffect(() => {
    setErrorState(defaultErrorObject)
  }, [
    newCollaborator.email,
    newCollaborator.firstName,
    newCollaborator.lastName,
  ])

  const validate = () => {
    const collaboratorIsValidResult = inviteCollaborator.validate({
      ...newCollaborator,
      jobId,
    })

    if (!collaboratorIsValidResult.success) {
      const zodErrors = collaboratorIsValidResult.error.errors
        .map(({ path, message }) => ({ name: path[0], message }))
        .reduce((acc, curr) => {
          return { ...acc, [curr.name]: curr.message }
        }, {}) as Record<string, string>

      setErrorState({
        email: zodErrors['email'],
        firstName: zodErrors['firstName'],
        lastName: zodErrors['lastName'],
      })
      return false
    }

    if (academicworkDomains.includes(newCollaborator.email.split('@')[1])) {
      setErrorState({
        email: 'Academic Work email addresses are not allowed.',
        firstName: undefined,
        lastName: undefined,
      })
      return false
    }

    if (collaborators.map((x) => x.email).includes(newCollaborator.email)) {
      setErrorState({
        email: 'Collaborator is already invited',
        firstName: undefined,
        lastName: undefined,
      })
      return false
    }
    return true
  }

  const onSubmit = async () => {
    if (validate()) {
      await inviteCollaborator.post({ ...newCollaborator, jobId })
      setErrorState(defaultErrorObject)
      onSubmitted()
    }
  }

  return (
    <>
      <InputContainer>
        <TextField
          placeholder="E-mail address"
          fullWidth
          error={Boolean(errorState.email)}
          helperText={errorState.email}
          value={newCollaborator.email}
          onChange={(event) => {
            setNewCollaborator((prev) => ({
              ...prev,
              email: event.target.value ?? '',
            }))
          }}
        />
        <TextField
          placeholder="Firstname"
          fullWidth
          error={Boolean(errorState.firstName)}
          helperText={errorState.firstName}
          value={newCollaborator.firstName}
          onChange={(event) => {
            setNewCollaborator((prev) => ({
              ...prev,
              firstName: event.target.value ?? '',
            }))
          }}
        />
        <TextField
          placeholder="Lastname"
          fullWidth
          error={Boolean(errorState.lastName)}
          helperText={errorState.lastName}
          value={newCollaborator.lastName}
          onChange={(event) => {
            setNewCollaborator((prev) => ({
              ...prev,
              lastName: event.target.value ?? '',
            }))
          }}
        />
      </InputContainer>

      <ButtonContainer>
        <Button
          fullWidth
          key="confirm-collab-modal"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
          Invite
        </Button>
      </ButtonContainer>
    </>
  )
}

const InputContainer = styled.div`
  & > div {
    margin-bottom: ${base * 3}px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
`
