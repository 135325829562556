import { css } from '@emotion/react'
import styled from '@emotion/styled'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { FC, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Presentation } from 'shared/collections/presentation.schema'
import { useReadmodel } from '../hooks/useReadmodel'
import { modalContentState } from '../state'
import { base } from '../styles/spacing'
import { getDateString } from '../utils/dateUtils'
import { postCommand } from '../utils/postCommand'
import { sortByDate } from '../utils/utils'
import { Alert } from './Alert'
import { Card } from './Card'
import { Confirm } from './Confirm'
import { Button, DiscreetButton } from './mui/Button'
import { IconButton } from './mui/IconButton'
import { TextField } from './mui/TextField'

type NotesProps = {
  presentationId: Presentation['_id']
}

export const Notes: FC<NotesProps> = ({ presentationId }) => {
  const [noteText, setNoteText] = useState<string>('')
  const [noNotesShown, setNoNotesShown] = useState(2)
  const [legalMessage, setLegalMessage] = useState(() => {
    const storedValue = localStorage.getItem('legalMessage')
    if (storedValue === 'show') {
      return 'closed'
    }
    return storedValue || 'notShown'
  })

  const setModalContent = useSetRecoilState(modalContentState)

  const [notes] = useReadmodel(
    'getNotesForPresentation',
    { presentationId },
    [],
  )

  const addNoteCommand = postCommand('addNote')
  const setNotesAsReadCommand = postCommand('setNotesAsRead')
  const deleteNoteCommand = postCommand('deleteNote')

  useEffect(() => {
    notes.sort((a, b) => sortByDate(a.created, b.created))

    return () => {
      const unreadNoteIds = notes.filter((x) => !x.readByMe).map((y) => y._id)
      if (unreadNoteIds.length) {
        setTimeout(() => {
          setNotesAsReadCommand.post({ presentationId, noteIds: unreadNoteIds })
        }, 3000)
      }
    }
  }, [notes])

  useEffect(() => {
    localStorage.setItem('legalMessage', legalMessage)
  }, [legalMessage])

  const savePresentation = async () => {
    const payload = {
      text: noteText || '',
      presentationId: presentationId,
    }
    try {
      if (!addNoteCommand.isValid(payload)) {
        alert('Incorrect data')
        return
      }
      await addNoteCommand.post(payload)
      setNoteText('')
    } catch (error) {
      alert(error)
    }
  }

  const handleDelete = async (noteId: string) => {
    try {
      await deleteNoteCommand.post({ presentationId, noteId })
    } catch (error) {
      alert(error)
    }
  }

  const openConfirmDeleteModal = (noteId: string) => {
    setModalContent({
      title:
        'Are you sure you want to delete this message? This cannot be undone.',
      content: <Confirm onConfirm={() => handleDelete(noteId)} />,
    })
  }

  return (
    <>
      <h2
        className="h2"
        css={css`
          margin-bottom: ${base * 3}px;
        `}
      >
        Dialog
      </h2>
      <CreateMessageContainer>
        <TextField
          onFocus={() => {
            if (legalMessage === 'notShown') {
              setLegalMessage('show')
            }
          }}
          onChange={(event) => setNoteText(event.target.value)}
          value={noteText}
          multiline
          placeholder="Type something..."
          size="medium"
        />
        <Button
          variant="contained"
          onClick={() => savePresentation()}
          size="large"
          color="secondary"
        >
          Send
        </Button>
      </CreateMessageContainer>
      {legalMessage === 'show' && (
        <Alert
          severity="info"
          styling={css`
            margin-bottom: ${base * 3}px;
          `}
          onClose={() => {
            setLegalMessage('closed')
          }}
          message="By using this service you will agree to take full responsibility for the content you post or share. Keep in mind that you are a subject to act under the European Data Protection legislations."
        />
      )}
      {notes?.length > 0 &&
        notes
          .slice(0, noNotesShown)
          .map(
            (
              { text, authorName, created, readByMe, isDeletable, _id },
              index,
            ) => (
              <Card
                key={`note-${index}`}
                outlined
                styling={css`
                  background-color: var(--background);
                  padding: ${base * 4}px;
                  margin-bottom: ${base * 3}px;
                `}
                onClick={() => {
                  if (!readByMe) {
                    setNotesAsReadCommand.post({
                      presentationId,
                      noteIds: [_id],
                    })
                  }
                }}
              >
                <MessageRowContainer>
                  <p>{text}</p>
                  {isDeletable && (
                    <IconButton onClick={() => openConfirmDeleteModal(_id)}>
                      <DeleteOutlined />
                    </IconButton>
                  )}
                </MessageRowContainer>
                <MessageRowContainer noMargin>
                  <p
                    className="caption"
                    css={css`
                      color: ${readByMe
                        ? 'var(--default-600)'
                        : 'var(--secondary-1000)'};
                    `}
                  >
                    {getDateString(new Date(created))}
                    {!readByMe ? ' - new' : ''}
                  </p>
                  <p
                    className="caption"
                    css={css`
                      color: var(--default-600);
                    `}
                  >
                    {authorName}
                  </p>
                </MessageRowContainer>
              </Card>
            ),
          )}
      {noNotesShown < notes.length && (
        <LoadMoreContainer>
          <DiscreetButton
            color="secondary"
            onClick={() => setNoNotesShown((prev) => prev + 4)}
          >
            Load older messages...
          </DiscreetButton>
        </LoadMoreContainer>
      )}
    </>
  )
}

const CreateMessageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${base * 2}px;
  align-items: start;
  margin-bottom: ${base * 3}px;
  gap: 8px;
  button {
    min-height: 48px;
  }
`

const MessageRowContainer = styled.div<{ noMargin?: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: start;
  margin-bottom: ${({ noMargin }) => `${base * (noMargin ? 0 : 3)}px`};
`

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${base * 2}px;
`
