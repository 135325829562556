import MUIIconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

const StyledIconButton = styled(MUIIconButton)({
  color: 'var(--default-600)',
  borderRadius: '16px',
  backgroundColor: 'var(--tertiary-1000)',

  '&:hover': {
    backgroundColor: '#FFEE94',
  },

  '&.MuiIconButton-colorPrimary': {
    backgroundColor: 'var(--primary-1000)',
    color: 'var(--tertiary-1000)',
  },

  '&.MuiIconButton-colorInfo': {
    backgroundColor: 'var(--info-color)',
    color: 'var(--primary-1000)',
    '&:hover': {
      backgroundColor: '#d0e1dd66',
    },
  },

  // '& svg': {
  //   fontSize: `${base * 6}px`,
  // },
})

export const IconButton = StyledIconButton
