import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Job } from 'shared/collections/job.schema'
import { base } from '../styles/spacing'
import { Presentation } from '../views/JobView'
import { Card } from './Card'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { postCommand } from '../utils/postCommand'
import { Tags } from './Tags'

type JobType = Job['jobType']

type PresentationCardProps = {
  presentation: Presentation
  hasNotification: boolean
  jobType: JobType
  isSelected: boolean
  onClickHandler: () => void
}

export const PresentationCard = ({
  presentation,
  hasNotification,
  jobType,
  isSelected,
  onClickHandler,
}: PresentationCardProps) => {
  const clearNotification = postCommand('clearPresentationNotification')
  const onClick = () => {
    if (hasNotification) {
      clearNotification.post({ presentationId: presentation._id })
    }
    onClickHandler()
  }

  return (
    <Card
      styling={css`
        /* border-left: ${base * 2}px solid ${getCardBorderColor(
          presentation,
        )}; */
        padding: ${base * 2}px;
        margin-bottom: ${base * 4}px;
        cursor: pointer;
        min-height: ${base * 36}px;
        position: relative;
        background-color: ${isSelected
          ? 'var(--primary-900)'
          : 'var(--surface)'};
        p,
        h3 {
          color: ${isSelected ? 'var(--surface)' : 'var(--primary-1000)'};
        }
        h3 {
          font-size: 20px;
        }
      `}
      onClick={onClick}
    >
      <CardContent
        borderColor={getCardContentBorderColor(isSelected, presentation)}
        full={!Boolean(presentation.decision)}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <h3
            className="h3"
            css={css`
              margin-bottom: ${base}px;
              font-size: 24px;
              font-weight: 400;
            `}
          >
            {presentation.name}
          </h3>
          {hasNotification && (
            <div>
              <NotificationsNoneOutlinedIcon
                css={css`
                  color: var(--default-300);
                `}
              />
              <NotificationDot />
            </div>
          )}
        </div>

        <AvailabilityAndPriceContainer>
          <p>{jobType === 'tempJob' ? 'Price' : 'Salary'}:</p>
          <p>{presentation.compensation || '-'}</p>
        </AvailabilityAndPriceContainer>
        <AvailabilityAndPriceContainer
          css={css`
            margin-bottom: ${base * 2}px;
          `}
        >
          <p>Available:</p>
          <p>{presentation.availability || '-'}</p>
        </AvailabilityAndPriceContainer>
        <Tags presentation={presentation} isSelected={isSelected} />
      </CardContent>
    </Card>
  )
}

const getCardBorderColor = (presentation: Presentation) => {
  if (presentation.decision === 'interested') {
    return 'var(--success-color)'
  } else if (presentation.decision === 'notInterested') {
    return 'var(--error-color)'
  }
  return 'none'
}

const getCardContentBorderColor = (
  isSelected: boolean,
  presentation: Presentation,
) => {
  if (presentation.decision === 'interested' && isSelected) {
    return 'var(--success-color)'
  } else if (presentation.decision === 'notInterested' && isSelected) {
    return 'var(--error-color)'
  } else if (isSelected) {
    return 'var(--default-300)'
  }
  return 'var(--surface)'
}

const CardContent = styled.div<{ borderColor: string; full: boolean }>`
  width: 100%;
  padding: ${base * 4}px;
  border-width: 1px;
  /* border-color: ${({ borderColor }) => borderColor};
  border-style: ${({ full }) => (full ? 'solid' : 'solid solid solid none')}; */
`

const AvailabilityAndPriceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: ${base * 2}px;
  justify-content: start;
  align-items: center;
  margin-bottom: ${base}px;
`

const NotificationDot = styled.div`
  width: ${base * 4}px;
  height: ${base * 4}px;
  background: var(--error-color);
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
`
