import styled from '@emotion/styled'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { modalOpenState } from '../state'
import { base } from '../styles/spacing'
import { postCommand } from '../utils/postCommand'
import { Autocomplete } from './mui/Autocomplete'
import { Button } from './mui/Button'
import { TextField } from './mui/TextField'

export const reasons: Record<string, string> = {
  notEnoughRelevantSkills: 'Not enough relevant skills',
  missingRelevantCertificationLicence: 'Missing relevant certification/licence',
  notEnoughRelevantExperience: 'Not enough relevant experience',
  notEnoughRelevantEducation: 'Not enough relevant education',
  cantMatchStartDate: "Can't match start date",
  cantMatchWorkingHours: "Can't match working hours",
  cantMatchLocation: "Can't match location",
  cantMatchPricing: "Can't match pricing",
  cantMatchSalaryClaim: "Can't match salary claim",
}

type ReasonSelectProps = {
  presentationId: string
  cmName: string
}

export const ReasonSelect = ({ presentationId, cmName }: ReasonSelectProps) => {
  const setOpen = useSetRecoilState(modalOpenState)
  const [reason, setReason] = useState<[string, string]>(
    Object.entries(reasons)[0],
  )
  const setPresentationAsNotInterestingCommand = postCommand(
    'setPresentationAsNotInteresting',
  )

  return (
    <Container>
      <p>{cmName} will get in touch with you to proceed with the next step</p>
      <Autocomplete
        options={Object.entries(reasons)}
        getOptionLabel={(option: [string, string]) => option[1]}
        size="small"
        sx={{
          width: `${base * 75}px`,
          margin: `${base * 4}px 0`,
        }}
        value={reason}
        isOptionEqualToValue={(
          option: [string, string],
          currentValue: [string, string],
        ) => (option[1] === currentValue[1] ? true : false)}
        onChange={(_: any, value: [string, string]) => {
          setReason(value)
        }}
        renderInput={(props: any) => (
          <TextField
            {...props}
            label="Reason"
            InputProps={{
              ...props.InputProps,
              style: { borderRadius: '8px', minHeight: '40px' },
            }}
          />
        )}
        disableClearable
      />
      <ButtonContainer>
        <Button
          fullWidth
          size="large"
          variant="text"
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled={!reason}
          onClick={() => {
            if (!reason) {
              return
            }
            setPresentationAsNotInterestingCommand.post({
              presentationId: presentationId,
              reason: reason[0],
            })
            setOpen(false)
          }}
        >
          Confirm
        </Button>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${base * 82}px;
  & > p {
    margin-bottom: ${base * 3}px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${base * 2}px;
  margin-top: ${base * 4}px;
`
