import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link } from 'react-router-dom'
import { GetJobsResponse } from 'shared/readmodels/getJobs.schema'
import { base, gutter } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import { Tag } from './Tag'

type Job = GetJobsResponse[0]

export const JobCard = ({ job }: { job: Job }) => {
  const calculateDaysLeft = (date: Date) => {
    const expirationDate = new Date(date.setDate(date.getDate() + 30))
    const timeDifference = expirationDate.getTime() - new Date().getTime()
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24))
    return `remove in ${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`
  }
  return (
    <Container>
      <div>
        <h3>{job.title}</h3>
        <p>Job-ID: {job.adaptPublicId}</p>
      </div>
      <ButtonContainer filled={job.filled}>
        {job.filled && job.filledDate && (
          <Tag
            styling={css`
              margin-right: ${gutter}px;
            `}
            label={calculateDaysLeft(new Date(job.filledDate))}
          />
        )}
        {job.presentationCount && job.presentationCount > 0 ? (
          <Link to={`/job/${job._id}`}>
            <IconButton size="large" aria-hidden>
              <ArrowForwardIcon data-testid="see-candidates" />
            </IconButton>
          </Link>
        ) : (
          <IconButton size="small" disabled>
            <ArrowForwardIcon data-testid="see-candidates" />
          </IconButton>
        )}
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 16px;
  padding: ${base * 8}px;
  margin-bottom: ${base * 4}px;
  background-color: var(--background);

  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: ${base * 4}px;
  }
`

const ButtonContainer = styled.div<{ filled?: boolean }>`
  display: flex;
  justify-content: ${({ filled }) => (filled ? `space-between` : `flex-end`)};
  margin-top: ${base * 4}px;
  align-items: center;
`
