import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { useReadmodel } from '../../hooks/useReadmodel'
import { base } from '../../styles/spacing'
import { Button } from '../mui/Button'
import { TabContext, TabPanel } from '../mui/Tabs'
import { CollaboratorList } from './CollaboratorList'
import { InviteCollaborator } from './InviteCollaborator'

export const Collaborators = ({
  jobId,
  mainContactId,
}: {
  jobId: NonNullable<GetJobByIdResponse>['_id']
  mainContactId: number
}) => {
  const [tabValue, setTabValue] = useState('1')
  const [collaborators] = useReadmodel('getCollaborators', { jobId }, [])

  return (
    <ContentWrapper>
      <TabContext value={tabValue}>
        <ButtonTabs>
          <TabButton
            variant="contained"
            color={tabValue === '1' ? 'secondary' : 'primary'}
            size="large"
            fullWidth
            onClick={() => setTabValue('1')}
            selected={tabValue === '1'}
          >
            Invite
          </TabButton>
          <TabButton
            variant="contained"
            color={tabValue === '2' ? 'secondary' : 'primary'}
            size="large"
            fullWidth
            onClick={() => setTabValue('2')}
            selected={tabValue === '2'}
          >
            Collaborators
          </TabButton>
        </ButtonTabs>
        {/* <TabList
          value={tabValue}
          onChange={(_, value) => setTabValue(value)}
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab value="1" label="Invite" />
          <Tab value="2" label="Collaborators" />
        </TabList> */}
        <TabPanel value="1">
          <InviteCollaborator
            jobId={jobId}
            collaborators={collaborators}
            onSubmitted={() => setTabValue('2')}
          />
        </TabPanel>
        <TabPanel value="2">
          <CollaboratorList
            jobId={jobId}
            mainContactId={mainContactId}
            collaborators={collaborators}
          />
        </TabPanel>
      </TabContext>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding: ${base * 4}px;
  width: ${base * 94}px;
  text-align: left;
`

const TabButton = styled(Button)<{ selected: boolean }>`
  ${({ selected }) =>
    !selected &&
    css`
      color: var(--primary-1000) !important;
      background-color: #fff !important;
    `}
`

const ButtonTabs = styled.div`
  background-color: var(--background);
  display: flex;
  gap: ${base}px;
  padding: ${base}px;
  border-radius: ${base * 4}px;
`
