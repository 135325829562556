export const AWLogo = () => {
  const height = 24
  return (
    <span aria-label="Company logo" style={{ height: `${height}px` }}>
      <svg
        width="82"
        height="28"
        viewBox="0 0 82 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M72.1549 0.736523L64.907 19.6174L57.6599 0.736523C57.5956 0.567721 57.4336 0.457205 57.2526 0.457205H49.1577C48.9775 0.457205 48.8156 0.568478 48.7505 0.736523L41.5283 19.5516L34.0594 0.0931062C34.0117 -0.0310354 33.8361 -0.0310354 33.7884 0.0931062L29.0021 12.5618L34.8209 27.7207C34.8852 27.8895 35.0472 28 35.2281 28H46.6802C46.8603 28 47.0223 27.8887 47.0874 27.7207L53.2059 11.7813L59.3244 27.7207C59.3888 27.8895 59.5508 28 59.7317 28H70.6652C70.8454 28 71.0074 27.8887 71.0725 27.7207L81.3096 1.04915C81.4194 0.763774 81.2082 0.457205 80.9024 0.457205H72.5614C72.3813 0.457205 72.2193 0.568478 72.1542 0.736523H72.1549Z"
          fill="#063025"
        />
        <path
          d="M24.0554 0.457205H10.6738C10.4936 0.457205 10.3316 0.568478 10.2665 0.736523L0.0294 27.4081C-0.0803594 27.6934 0.130833 28 0.436645 28H8.48693C8.66709 28 8.82908 27.8887 8.89417 27.7207L10.0084 24.817H23.9872C24.1674 24.817 24.3294 24.7057 24.3937 24.5377L29.0021 12.5618L24.4626 0.736523C24.3983 0.567721 24.2363 0.457205 24.0554 0.457205ZM12.6268 17.9952L17.1473 6.21919L21.6679 17.9952H12.6268Z"
          fill="#063025"
        />
      </svg>
    </span>
  )
}
