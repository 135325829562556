import { css } from '@emotion/react'
import styled from '@emotion/styled'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import FolderOpenOutlined from '@mui/icons-material/FolderOpenOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSetRecoilState } from 'recoil'
import { Job } from 'shared/collections/job.schema'
import { modalContentState, snackbarMessageState } from '../state'
import { base, gutter, md } from '../styles/spacing'
import { getDateString } from '../utils/dateUtils'
import { postCommand } from '../utils/postCommand'
import { Presentation } from '../views/JobView'
import { Avatar } from './Avatar'
import { Card } from './Card'
import { Confirm } from './Confirm'
import { Link } from './Link'
import { Button, DiscreetButton } from './mui/Button'
import { IconButton } from './mui/IconButton'
import { TextField } from './mui/TextField'
import { Notes } from './Notes'
import { ReasonSelect } from './ReasonSelect'
import { Tags } from './Tags'
import UAParser from 'ua-parser-js'
const UA = new UAParser()

type PresentationDetailsProps = {
  presentation: Presentation | undefined
  jobType: Job['jobType']
  cmName: string
  onClose?: () => void
}

export const PresentationDetails = ({
  presentation,
  jobType,
  cmName,
  onClose,
}: PresentationDetailsProps) => {
  const setModalContent = useSetRecoilState(modalContentState)
  const setSnackbarValue = useSetRecoilState(snackbarMessageState)

  const setPresentationAsViewed = postCommand('setPresentationAsViewed')
  const setPresentationAsInterestingCommand = postCommand(
    'setPresentationAsInteresting',
  )
  const addTagCommand = postCommand('addTag')
  const deleteTagCommand = postCommand('deleteTag')

  useEffect(() => {
    if (presentation && !presentation.viewed) {
      setPresentationAsViewed.post({ presentationId: presentation._id })
    }
  }, [presentation])

  if (!presentation) {
    return null
  }

  const handleNotInteresting = () => {
    setModalContent({
      title: 'Select reason',
      content: (
        <ReasonSelect presentationId={presentation._id} cmName={cmName} />
      ),
    })
  }

  const handleIntersting = () => {
    setModalContent({
      title: 'Confirm interest?',
      content: (
        <Confirm
          subText={`${cmName} will get in touch with you to proceed with the next step`}
          onConfirm={() =>
            setPresentationAsInterestingCommand.post({
              presentationId: presentation._id,
            })
          }
        />
      ),
    })
  }

  const downloadFile = async (id: string) => {
    try {
      const response = await axios.get(`/api/attachment/${id}`, {
        responseType: 'blob',
      })
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replaceAll('"', '')
      const fileType = response.headers['content-type']
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: fileType }),
      )
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = url
      if (UA.getBrowser().name === 'Mobile Safari') {
        link.setAttribute('download', fileName)
      }
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      setSnackbarValue({
        message: 'An error occured when downloading file',
        severity: 'error',
      })
    }
  }

  return (
    <Card
      styling={css`
        padding: 24px;
      `}
    >
      <HeaderContainer>
        <Avatar />
        <h1 className="h1">{presentation.name}</h1>
        {onClose && (
          <IconButton
            color="primary"
            css={css`
              place-self: start end;
            `}
            onClick={onClose}
          >
            <CloseOutlined />
          </IconButton>
        )}
      </HeaderContainer>
      <div
        css={css`
          margin-bottom: ${base * 2}px;
        `}
      >
        <TagInput
          onNewTag={(tag) =>
            addTagCommand.post({ presentationId: presentation._id, tag })
          }
        />
      </div>

      <Tags
        onRemoveTag={(tag) =>
          deleteTagCommand.post({ presentationId: presentation._id, tag })
        }
        presentation={presentation}
        css={css`
          margin-bottom: ${base * 6}px;
        `}
      />

      <AvailabilityAndPriceContainer>
        <h4 className="h4">Availability</h4>
        <h4 className="h4">{jobType === 'tempJob' ? 'Price' : 'Salary'}</h4>
        <h4 className="h4">Presented</h4>
        <p>{presentation.availability || '-'}</p>
        <p>{presentation.compensation || '-'}</p>
        <p>
          {presentation.presentedDate
            ? getDateString(new Date(presentation.presentedDate))
            : '-'}
        </p>
      </AvailabilityAndPriceContainer>

      <h2
        className="h2"
        css={css`
          margin-bottom: ${base * 3}px;
        `}
      >
        Presentation
      </h2>
      <Description>
        <ReactMarkdown
          components={{
            a: ({ children, href }) => (
              <Link href={href} targetBlank>
                {children}
              </Link>
            ),
          }}
        >
          {presentation.description || ''}
        </ReactMarkdown>
      </Description>

      <h2
        className="h2"
        css={css`
          margin-bottom: ${base * 3}px;
        `}
      >
        Attachments
      </h2>
      {presentation.attachments?.length > 0 ? (
        <div
          css={css`
            margin-bottom: ${gutter}px;
          `}
        >
          {presentation.attachments?.map(({ name, _id }) => (
            <Attachment
              role="button"
              key={_id}
              onClick={() => downloadFile(_id)}
            >
              <InsertDriveFileOutlinedIcon
                css={css`
                  margin-right: ${base * 4}px;
                `}
              />
              <p>{name}</p>
            </Attachment>
          ))}
        </div>
      ) : (
        <p
          key="no-attachments"
          css={css`
            margin-bottom: ${gutter}px;
          `}
        >
          No attachments exist
        </p>
      )}

      {!presentation.decision && (
        <ButtonsContainer>
          <Button
            variant="contained"
            color="info"
            size="large"
            onClick={handleNotInteresting}
          >
            Not interesting
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleIntersting}
            // sx={{ marginBottom: `${base * 2}px` }}
          >
            Interesting
          </Button>
        </ButtonsContainer>
      )}
      <Notes presentationId={presentation._id} />
    </Card>
  )
}

const ButtonsContainer = styled.div`
  margin-bottom: ${gutter}px;
  display: flex;
  justify-content: flex-end;
  gap: ${base * 2}px;

  @media (max-width: ${md}px) {
    flex-direction: column-reverse;
  }
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: ${base * 16}px 1fr auto;
  grid-column-gap: ${base * 4}px;
  align-items: center;
  margin-bottom: ${base * 4}px;
`

const TagContainer = styled.div`
  display: inline-block;
  margin-bottom: ${base * 4}px;
`

const AvailabilityAndPriceContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: ${base * 6}px;
  margin-bottom: ${gutter}px;

  h4 {
    font-family: 'Peachi';
    font-size: 14px;
  }
`

const Description = styled.div`
  margin-bottom: ${gutter}px;
  & em {
    font-weight: normal;
  }
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    margin-top: 0px;
    margin-bottom: ${base * 3}px;
  }
  & p,
  a {
    font-size: ${base * 4}px;
  }
`

const Attachment = styled.a`
  display: flex;
  align-items: center;
  background-color: var(--background);
  border-radius: 16px;
  padding: ${base * 8}px;
  margin-bottom: ${base * 3}px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background-color: var(--info-background);
  }
`

type TagInputProps = {
  onNewTag: (tag: string) => void
}

const TagInput = ({ onNewTag }: TagInputProps) => {
  const [value, setValue] = useState('')

  const handleSubmit = () => {
    onNewTag(value.toLowerCase())
    setValue('')
  }

  return (
    <Wrapper>
      <TextField
        placeholder="Add custom label"
        value={value}
        fullWidth
        onKeyUp={(e) => {
          if (e.key !== 'Enter') return

          handleSubmit()
        }}
        onChange={(event) => {
          setValue(event.target.value)
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        size="large"
        disabled={value.length < 1}
      >
        Add
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${base * 2}px;
`
