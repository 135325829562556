import { useEffect, useRef, useState } from 'react'
import { Chevron } from './ChevronIcon'
import { UserMenu } from './UserMenu'
import styled from '@emotion/styled'
import { ProfileIcon } from './ProfileIcon'

export function AuthMenu() {
  const [isOpen, setIsOpen] = useState<boolean | undefined>()
  const menuRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: Event) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => document.body.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <Container ref={menuRef}>
      <AuthButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <UserInitialsWrapper className="avatar">
          <UserInitialsContent className="avatar-fallback">
            <ProfileIcon />
          </UserInitialsContent>
        </UserInitialsWrapper>
        <StyledChevron isOpen={isOpen} />
        <Connector isOpen={isOpen}>
          <StyledExit />
        </Connector>
      </AuthButton>
      <ContentWrapper isOpen={isOpen}>
        <UserMenu />
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  font-family: 'Jokker';
`

const Connector = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  width: calc(100% + 2px);
  height: 24px;
  top: calc(100% - 8px);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  right: -1px;
  background-color: #fafffa;
  transform: translate(0, -0.75rem) rotate(0) skewX(0) skewY(0) scaleX(1)
    scaleY(1);
`

const StyledExit = styled.div`
  position: absolute;
  bottom: 0;
  right: 100%;
  transform: rotate(-90deg);
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fafffa;
  clip-path: path(
    'M0 -8.74228e-07L-8.74228e-07 20L20 20C8.95428 20 -4.82822e-07 11.0457 0 -8.74228e-07Z'
  );
`

const StyledChevron = styled(Chevron)<{ isOpen?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
  z-index: 1;
  margin-right: -0.5rem;
`

const ContentWrapper = styled.div<{ isOpen?: boolean }>`
  position: relative;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: ${({ isOpen }) =>
    isOpen === undefined ? 'none' : 'all 150ms ease-in-out'};
  transform-origin: top right;
  transform: ${({ isOpen }) =>
    isOpen
      ? 'translateY(0) scale3d(1, 1, 1)'
      : 'translateY(-2rem) scale3d(0.95, 0.95, 0.95)'};
`

const UserInitialsContent = styled.div`
  color: #fafffa;
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: #06281f;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
`
const UserInitialsWrapper = styled.div`
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  position: relative;
  z-index: 1;
`

const AuthButton = styled.button<{ isOpen?: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  background-color: #fafffa;
  position: relative;
  border-radius: 16px;
  white-space: nowrap;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
  display: flex;
  cursor: pointer;
  border: ${({ isOpen }) =>
    isOpen ? `1px solid transparent` : `1px solid #d0e1dd`};

  box-shadow: ${({ isOpen }) =>
    isOpen
      ? 'none'
      : 'rgba(255, 255, 255, 0.33) 0px 0px 0px 0px inset,rgba(208, 225, 221, 0.33) 0px 0px 0px 0.329225px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px'};

  &:hover {
    background-color: ${({ isOpen }) => (isOpen ? '#fafffa' : '#dceee9')};
  }
`
