import { styled } from '@mui/material/styles'
import MUITextField, { TextFieldProps } from '@mui/material/TextField'

const StyledTextField = styled(MUITextField)({
  backgroundColor: 'var(--surface)',

  '& label': {
    top: '-12px',
    fontFamily: 'Jokker',
    fontSize: 16,
    '&.Mui-focused': {
      color: 'var(--secondary-1000)',
    },
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
    '&.Mui-error': {
      color: 'var(--error-color)',
    },
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Jokker',
    fontSize: 16,
    color: 'var(--primary-1000)',
    borderRadius: 0,
    padding: '4px 12px',
    '& fieldset': { borderColor: 'var(--default-300)' },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--secondary-1000)',
    },
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'var(--error-color)',
  },
  '& .MuiFormHelperText-root': {
    color: 'var(--error-color)',
  },
})

export const TextField = (props: TextFieldProps) => (
  <StyledTextField
    InputProps={{ style: { borderRadius: '8px', minHeight: '48px' } }}
    {...props}
  />
)
