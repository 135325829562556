import styled from '@emotion/styled'
import { SettingsIcon } from './SettingsIcon'
import { ProfileIcon } from './ProfileIcon'
import { LogoutIcon } from './LogoutIcon'
import { useSetRecoilState } from 'recoil'
import { modalContentState } from '../../state'
import { Confirm } from '../Confirm'
import { deleteAllCookies } from '../../utils/utils'

const signOutUser = () => {
  deleteAllCookies()
  window.location.assign('/api/logout')
}

export function UserMenu() {
  const setModalContent = useSetRecoilState(modalContentState)
  const openSignOutConfirmModal = () => {
    setModalContent({
      title: 'Are you sure you want to sign out?',
      content: <Confirm onConfirm={signOutUser} />,
    })
  }

  const handleSignOutClick = () => {
    openSignOutConfirmModal()
  }

  const authUi = `https://account.academicwork.com`

  return (
    <MenuList className="user-menu">
      <ListItem>
        <MenuLink href={`${authUi}/settings`} className="menu-item">
          <SettingsIcon className="icon" />
          Settings
        </MenuLink>
      </ListItem>
      <ListItem>
        <MenuButton onClick={handleSignOutClick} className="menu-item sign-out">
          <LogoutIcon className="icon" />
          Sign out
        </MenuButton>
      </ListItem>
    </MenuList>
  )
}

const MenuList = styled.ul`
  list-style: none;
  position: absolute;
  right: 0;
  bottom: -0.2rem;
  padding: 4px;
  background-color: #fafffa;
  border-radius: 12px;
  border-top-right-radius: 0;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;
  text-wrap: nowrap;
  min-width: 160px;
  transform: translateY(100%) scale3d(0.95, 0.95, 0.95);
  transition: all 150ms ease-in-out;
  transform-origin: top right;
`

const menuItemStyle = `
  color: #06281f;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgb(235 247 242 / var(--tw-bg-opacity, 1));
  border-radius: 12px;
  gap: 0.25rem;
  white-space: nowrap;
  height: 3rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #dceee9;
  }
`

const ListItem = styled.li`
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const MenuLink = styled.a`
  ${menuItemStyle}
`
const MenuButton = styled.button`
  ${menuItemStyle}
  appearance: none;
  border: none;
  width: 100%;
`
