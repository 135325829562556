import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { base, maxPageWidth } from '../styles/spacing'
import { Card } from './Card'
import { Tag } from './Tag'
import { IconButton } from './mui/IconButton'
import Close from '@mui/icons-material/Close'

type FilterMenuProps = {
  tags: string[]
  filteredTags: string[]
  onFilterUpdate: (tags: string[]) => void
  simpleView: boolean
  onClose: () => void
}

export const FilterMenuCard = ({
  tags,
  filteredTags,
  onFilterUpdate,
  simpleView,
  onClose,
}: FilterMenuProps) => {
  return (
    <Card
      styling={css`
        max-width: ${maxPageWidth}px;
        margin: 0 auto;
        margin-bottom: ${base * 4}px;
        position: relative;
      `}
    >
      <CardContent
        css={css`
          display: flex;
          padding: ${base * 3}px ${base * 3}px ${base}px ${base * 3}px;
        `}
      >
        <CardHeader>
          <div>
            <h3>Filter</h3>
            <p>Labels</p>
          </div>
          <div>
            <IconButton color="info" onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </CardHeader>
        <TagList horizontal={true} boardView={true}>
          {tags.map((t) => (
            <li key={t}>
              <TagButton
                css={css`
                  border-radius: 20px;
                `}
                onClick={() => {
                  let newTags = []
                  if (filteredTags.includes(t)) {
                    newTags = filteredTags.filter((tag) => tag !== t)
                  } else {
                    newTags = [...filteredTags, t]
                  }
                  onFilterUpdate(newTags)
                }}
              >
                <Tag
                  color={filteredTags.includes(t) ? 'outlined' : 'default'}
                  label={t}
                />
              </TagButton>
            </li>
          ))}
        </TagList>
      </CardContent>
    </Card>
  )
}

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 8px;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: ${base * 2}px;
  }
`
const TagList = styled.ul<{ horizontal: boolean; boardView?: boolean }>`
  list-style: none;
  margin: 0;
  padding: ${({ boardView }) => (boardView ? `0px 0px 0px ${base * 12}px` : 0)};

  display: flex;
  flex-wrap: wrap;
  padding: 0;

  ${({ horizontal }) =>
    horizontal &&
    css`
      display: flex;
      gap: ${base * 2}px;
    `}

  li:not(:last-child) {
    margin-bottom: ${base * 2}px;
  }
`
const TagButton = styled.button`
  background: 0;
  border: 0;
  cursor: pointer;
`
