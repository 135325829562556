import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { ContentArea } from '../App'
import { Collaborators } from '../components/collaborator/Collaborators'
import { FilterMenuCard } from '../components/FilterMenu'
import { JobDoesNotExist } from '../components/JobDoesNotExist'
import { JobHeader } from '../components/JobHeader'
import { CircularProgress } from '../components/mui/CircularProgress'
import { PresentationBoard } from '../components/PresentationBoard'
import { PresentationCard } from '../components/PresentationCard'
import { PresentationDetails } from '../components/PresentationDetails'
import { useReadmodel } from '../hooks/useReadmodel'
import { modalContentState, userState } from '../state'
import { base, gutter, lg, md } from '../styles/spacing'
import { isSmallScreen, notEmpty } from '../utils/utils'
import { Card } from '../components/Card'

export type Presentation = NonNullable<GetJobByIdResponse>['presentations'][0]

export const JobView = () => {
  const { id } = useParams<{ id: string }>()
  const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true)
  const [job, isLoading] = useReadmodel('getJobById', { id: id! }, null)
  const [notificationPresentationIds] = useReadmodel(
    'getNotifications',
    { jobId: id! },
    [],
  )
  const [selectedPresentationId, setSelectedPresentationId] = useState<string>()
  const [filteredTags, setFilteredTags] = useState<string[]>([])
  const [filterOpen, setFilterOpen] = useState(false)
  const setModalContent = useSetRecoilState(modalContentState)
  const user = useRecoilValue(userState)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (job) {
      setIsInitialFetch(false)
    }
    const presentationId = searchParams.get('presentationId')
    const presentation = presentationId
      ? job?.presentations.find((x) => x._id === presentationId)
      : null
    if (presentationId && presentation) {
      setSelectedPresentationId(presentationId)
    }
  }, [job])

  const handleInviteCollaborators = () => {
    setModalContent({
      title: 'Collaborators',
      content: job?.contactId ? (
        <Collaborators jobId={job._id} mainContactId={job.contactId} />
      ) : (
        <></>
      ),
      showCloseButton: true,
    })
  }

  if (!user) {
    return <BlankPage />
  }

  if (isInitialFetch && isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={60} aria-label="Loading" />
      </LoaderWrapper>
    )
  }

  if ((!job || !Object.keys(job).length) && !isLoading) {
    return <JobDoesNotExist />
  }

  const selectedPresentation = job?.presentations?.find(
    (pres) => pres._id === selectedPresentationId,
  )

  const onlyShowDetails = Boolean(selectedPresentationId && isSmallScreen())

  const uniqueTags = [
    ...new Set(job?.presentations.flatMap((p) => p.tags).filter(notEmpty)),
  ]

  if (job?.view === 'board') {
    return (
      <ContentArea
        css={css`
          max-width: 100%;
          @media (min-width: ${lg}px) {
            padding: ${base * 6}px;
          }
        `}
      >
        <div>
          <JobHeader
            onlyShowDetails={onlyShowDetails}
            job={job!}
            handleInviteCollaborators={handleInviteCollaborators}
            onFilterClick={() => {
              setFilterOpen(!filterOpen)
            }}
          />
          <PresentationBoard
            setFilterOpen={setFilterOpen}
            job={job}
            notificationPresentationIds={notificationPresentationIds}
            filterOpen={filterOpen}
            tags={uniqueTags}
            filteredTags={filteredTags}
            onFilterUpdate={setFilteredTags}
          />
        </div>
      </ContentArea>
    )
  }

  return (
    <ContentArea>
      <div>
        <JobHeader
          onlyShowDetails={onlyShowDetails}
          job={job!}
          handleInviteCollaborators={handleInviteCollaborators}
          onFilterClick={() => {
            setFilterOpen(!filterOpen)
          }}
        />
        <PresentationsWrapper>
          <PresentationCardsWrapper onlyShowDetails={!!onlyShowDetails}>
            {filterOpen && (
              <FilterMenuCard
                onClose={() => setFilterOpen(false)}
                tags={uniqueTags}
                filteredTags={filteredTags}
                onFilterUpdate={setFilteredTags}
                simpleView={job?.view === 'simple'}
              />
            )}
            {job?.presentations &&
              job?.presentations
                .filter((presentation) =>
                  filteredTags.length
                    ? filteredTags.some((tag) =>
                        presentation.tags?.includes(tag),
                      )
                    : true,
                )
                .map((presentation) => (
                  <PresentationCard
                    key={presentation._id}
                    presentation={presentation}
                    hasNotification={notificationPresentationIds.includes(
                      presentation._id,
                    )}
                    jobType={job?.jobType}
                    isSelected={selectedPresentationId === presentation._id}
                    onClickHandler={() => {
                      setSelectedPresentationId(presentation._id)
                      setSearchParams((params) => {
                        params.set('presentationId', presentation._id)
                        return params
                      })
                    }}
                  />
                ))}
          </PresentationCardsWrapper>
          <PresentationDetailsSurfaceWrapper>
            {selectedPresentationId && selectedPresentation ? (
              <PresentationDetails
                presentation={selectedPresentation}
                jobType={job!.jobType}
                cmName={job?.cmName || ''}
                onClose={() => {
                  setSelectedPresentationId(undefined)
                  setSearchParams((params) => {
                    params.delete('presentationId')
                    return params
                  })
                }}
              />
            ) : (
              <Card
                styling={css`
                  min-height: 500px;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  h3 {
                    font-weight: 400;
                    text-align: center;
                  }
                `}
              >
                <h3>
                  Select a candidate in the list to view their presentation here
                </h3>
              </Card>
            )}
          </PresentationDetailsSurfaceWrapper>
        </PresentationsWrapper>
      </div>
    </ContentArea>
  )
}

const BlankPage = styled.div`
  background-color: var(--surface);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`

const PresentationsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PresentationCardsWrapper = styled.div<{ onlyShowDetails: boolean }>`
  width: 100%;
  display: ${({ onlyShowDetails }) => (onlyShowDetails ? 'none' : 'block')};
  visibility: ${({ onlyShowDetails }) =>
    onlyShowDetails ? 'hidden' : 'visible'};
  @media (min-width: ${md}px) {
    width: 40%;
  }
`

const PresentationDetailsSurfaceWrapper = styled.div`
  width: 100%;

  @media (min-width: ${md}px) {
    width: calc(60% - 16px);
    margin-left: 16px;
  }
`
