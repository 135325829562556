import styled from '@emotion/styled'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { GetJobsResponse } from 'shared/readmodels/getJobs.schema'
import { useReadmodel } from '../hooks/useReadmodel'
import { base } from '../styles/spacing'
import { Autocomplete } from './mui/Autocomplete'
import { CircularProgress } from './mui/CircularProgress'
import { TextField } from './mui/TextField'
import { useNavigate } from 'react-router-dom'

type Job = GetJobsResponse[0]

export const JobDoesNotExist = () => {
  const [jobs, isLoading] = useReadmodel('getJobs', null, null)
  const navigate = useNavigate()

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={60} aria-label="Loading" />
      </LoaderWrapper>
    )
  }

  return (
    <Container>
      {jobs ? (
        <>
          <h2 className="h2">
            The job you've tried to access is no longer active.
          </h2>
          <p>Please select another job in the list below:</p>
          <Autocomplete
            size="small"
            options={jobs}
            getOptionLabel={(job: Job) => `${job.title} (${job.adaptPublicId})`}
            filterOptions={createFilterOptions({
              stringify: (job: Job) => `${job.title}`,
            })}
            renderInput={(params: any) => (
              <TextField {...params} size="small" placeholder="Select job" />
            )}
            onChange={(_: Event, job: Job) => {
              navigate(`/job/${job._id}`)
            }}
            disableClearable
            sx={{ width: '334px' }}
          />
        </>
      ) : (
        <>
          <h2 className="h2">
            The job you've tried to access is no longer active.
          </h2>
          <p>
            At the moment you have no other ongoing jobs with Academic Work.
          </p>
        </>
      )}
    </Container>
  )
}

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding-top: ${base * 60}px;
  & > h2,
  p {
    margin-bottom: ${base * 2}px;
  }
`
