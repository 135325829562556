import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'
import { JobCard } from '../components/JobCard'
import { CircularProgress } from '../components/mui/CircularProgress'
import { useReadmodel } from '../hooks/useReadmodel'
import { userState } from '../state'
import { base, gutter } from '../styles/spacing'

export const Home = () => {
  const user = useRecoilValue(userState)
  const [jobs, isLoading] = useReadmodel('getJobs', null, [])

  if (!user) {
    return <BlankPage />
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={60} aria-label="Loading" />
      </LoaderWrapper>
    )
  }

  if (!jobs.length) {
    return (
      <NoJobContainer>
        <h2 className="h2">
          At the moment you have no ongoing jobs with Academic Work.
        </h2>
      </NoJobContainer>
    )
  }

  const isFilledDateWithinActivePeriod = (dateString: Date) => {
    const filledDate = new Date(dateString)

    return new Date() <= new Date(filledDate.setDate(filledDate.getDate() + 30))
  }

  return (
    <>
      <Card>
        <h1>Open jobs</h1>
        <JobsList>
          {jobs
            .filter((job) => !job.filled)
            .map((job, key) => (
              <JobCard job={job} key={key} />
            ))}
        </JobsList>
      </Card>

      {jobs.some(
        (job) =>
          job.filledDate && isFilledDateWithinActivePeriod(job.filledDate),
      ) && (
        <Card>
          <h2>Filled jobs</h2>
          <JobsList>
            {jobs
              .filter(
                (job) =>
                  job.filledDate &&
                  job.filled &&
                  isFilledDateWithinActivePeriod(job.filledDate),
              )
              .map((job, key) => (
                <JobCard job={job} key={key} />
              ))}
          </JobsList>
        </Card>
      )}
    </>
  )
}

const BlankPage = styled.div`
  background-color: var(--surface);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`

const NoJobContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${base * 20}px;
`

const Card = styled.div`
  background-color: var(--surface);
  padding: ${gutter}px;
  margin-bottom: ${gutter}px;
  border-radius: 16px;
  h1 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${gutter}px;
  }
`
const JobsList = styled.div`
  display: flex;
  flex-direction: column;
`
