import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { base, gutter, maxPageWidth, md } from '../styles/spacing'
import { Card } from './Card'
import { Button } from './mui/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import { IconButton } from './mui/IconButton'

type JobHeaderProps = {
  onlyShowDetails: boolean
  job: GetJobByIdResponse
  handleInviteCollaborators: () => void
  onFilterClick?: () => void
}

export const JobHeader = ({
  onlyShowDetails,
  job,
  handleInviteCollaborators,
  onFilterClick,
}: JobHeaderProps) => {
  return (
    <>
      <Card
        styling={css`
          padding: ${gutter}px;
          display: ${onlyShowDetails ? 'none' : 'block'};
          visibility: ${onlyShowDetails ? 'hidden' : 'visible'};
          max-width: ${maxPageWidth}px;
          margin: 0 auto;
        `}
      >
        <HeadingContainer>
          <h2 className="h2">{job?.title}</h2>
        </HeadingContainer>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <RecruiterInfoContainer>
            <div>
              <h3>Recruiter</h3>
              <p>{job?.cmName}</p>
            </div>
            <div>
              <h3>Phone</h3>
              <p>{job?.cmPhone || '-'}</p>
            </div>
            <div>
              <h3>E-mail</h3>
              <p>{job?.cmEmail}</p>
            </div>
          </RecruiterInfoContainer>
        </div>
      </Card>
      <ButtonsContainer>
        {onFilterClick && (
          <IconButton
            color="info"
            size="large"
            onClick={() => {
              onFilterClick()
            }}
          >
            <FilterListIcon />
          </IconButton>
        )}
        <Button
          variant="contained"
          color="info"
          size="large"
          onClick={handleInviteCollaborators}
        >
          Invite collaborators
        </Button>
      </ButtonsContainer>
    </>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${base * 2}px;
  max-width: ${maxPageWidth}px;
  margin: 16px auto;
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${gutter}px;
  h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
  }
`

const RecruiterInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: ${base * 12}px;
  justify-content: start;

  h3 {
    font-weight: 400;
  }

  @media (max-width: ${md}px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${base * 4}px;
  }
`
