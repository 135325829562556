import styled from '@emotion/styled'
import { GetJobByIdResponse } from 'shared/readmodels/getJobById.schema'
import { base } from '../styles/spacing'
import { reasons } from './ReasonSelect'
import { Tag } from './Tag'

type TagsProps = {
  presentation: NonNullable<GetJobByIdResponse>['presentations'][0]
  className?: string
  onRemoveTag?: (tag: string) => void
  isSelected?: boolean
}

export const Tags = ({
  presentation,
  className,
  onRemoveTag,
  isSelected,
}: TagsProps) => {
  const tags = presentation.tags

  const showNewTag = !presentation.viewed
  const showActionNeededTag = presentation.viewed && !presentation.decision

  if (
    !showNewTag &&
    !presentation.decision &&
    !tags?.length &&
    !showActionNeededTag
  ) {
    return null
  }

  return (
    <TagsWrapper className={className}>
      {showNewTag && (
        <Tag label="New" color={isSelected ? 'bright' : 'default'} />
      )}
      {showActionNeededTag && (
        <Tag label="Action needed" color={isSelected ? 'bright' : 'default'} />
      )}
      {presentation.decision === 'interested' && (
        <Tag label="Interesting" color={isSelected ? 'bright' : 'default'} />
      )}
      {presentation.decision === 'notInterested' &&
        presentation.reasonForDecision && (
          <Tag
            color={isSelected ? 'bright' : 'default'}
            label={
              reasons[
                presentation.reasonForDecision || 'notEnoughRelevantSkills'
              ]
            }
          />
        )}
      {tags?.map((tag) => (
        <Tag
          color={isSelected ? 'bright' : 'default'}
          key={tag}
          label={tag}
          onRemove={
            onRemoveTag &&
            (() => {
              onRemoveTag(tag)
            })
          }
        />
      ))}
    </TagsWrapper>
  )
}

const TagsWrapper = styled.div`
  display: flex;
  gap: ${base * 2}px;
  flex-wrap: wrap;
`
