import styled from '@emotion/styled'
import { base } from '../styles/spacing'
import { IconButton } from './mui/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { ReactNode } from 'react'

type RightDrawerProps = {
  open: Boolean
  setOpen?: (open: Boolean) => void
  children: ReactNode
  onClose?: () => void
}

export const RightDrawer = ({
  open,
  setOpen,
  children,
  onClose,
}: RightDrawerProps) => {
  const exitDrawer = () => {
    setOpen && setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  if (!open) {
    return null
  }

  return (
    <StyledRightDrawer>
      {setOpen && (
        <StyledHeader>
          <IconButton color="primary" onClick={exitDrawer}>
            <CloseOutlined />
          </IconButton>
        </StyledHeader>
      )}
      <StyledContent>{children}</StyledContent>
    </StyledRightDrawer>
  )
}

const StyledRightDrawer = styled.div`
  position: fixed;
  background-color: var(--surface);
  top: 0;
  left: auto;
  right: 0;
  width: ${base * 180}px;
  height: 100%;
  box-shadow: var(--elevation-1);
  z-index: 3;
  @media (max-width: 900px) {
    width: 100%;
  }
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${base * 2}px;
`

const StyledContent = styled.div`
  height: 90%;
  overflow-y: auto;
`
